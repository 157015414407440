const Radio = {
  parts: ["container", "control", "label"],
  baseStyle: {
    control: {
      _checked: {
        background: "bluefrance",
        borderColor: "bluefrance",
      },
    },
  },
};

export { Radio };
